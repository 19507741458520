// useWebSocket.js
import { useState, useEffect, useRef, useCallback } from 'react';
import { io, Socket } from "socket.io-client";
import useInterval from './interval';
import { getSocket } from '../utils/socket';

const useLaserSocket = (station: string, laserPos: number) => {

    const [distance, setDistance] = useState("0");
    const device = useRef<any>(null);
    
    const socketRef = useRef<Socket>();

    const setSocket = (socket:Socket) => {
        socketRef.current = socket;
    }

    // const socket = socketRef.current;



    useEffect(() => {
        if(station.trim().length > 0){
            // const socketOwn = io(`https://data.data2work.com/${station.toLowerCase()}`, {
            //     transports: ["websocket"]
    
            // });

            const socketOwn = getSocket(`https://data.data2work.com/${station.toLowerCase()}`);

            setSocket(socketOwn);

            return () => {
                if (device.current) {
                    console.log(`emit disconnect to ${device.current.id!.toLowerCase()}`, '{"pos":' + laserPos.toString() + '}');

                    socketOwn.emit("dis", device.current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}')
                }
    
                // try {
                //     if(socketOwn.connected){
                //         socketOwn.disconnect();
                //     }
                // } catch (error) {
                //     // nothing
                //     console.log("disconnect error", error);
                    
                // }
            };
        }


    }, [laserPos, station]);

    useEffect(() => {
        // const socket = socketRef.current;

        function onConnect() {
            const socket = socketRef.current;

            console.log("Connected to Socket");

            if (socket && socket.connected && device.current) {
                console.log(`emit connect to ${device.current.id!.toLowerCase()}`);
                socket.emit("con", device.current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}');
            }
        }
        
        function onTelemetryEvent(...data: any[]) {
            if (device.current && device.current.id === data[0]) {
                let json = JSON.parse(data[1])
                console.log("json['distance']", json['distance']);
                
                setDistance(json['distance']);
            }
        }

        if(station.trim().length > 0){

            const socket = socketRef.current;
    
            if (!socket) {
                return;
            }
    
    
            socket.on('connect', onConnect);
        
            socket.on('telemetry', onTelemetryEvent);
    
            if (socket.connected && device.current) {
                console.log(`emit connect to ${device.current.id!.toLowerCase()}`);
                socket.emit("con", device.current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}');
            }
    
            return () => {
                console.log("clear telemetry", station, device.current.id!.toLowerCase());
                
                socket.off('connect', onConnect);
    
                socket.off('telemetry', onTelemetryEvent);
            };
        }

    }, [laserPos, station]);

    useInterval(() => {
        const socket = socketRef.current;

        if (socket?.connected) {
            console.log(`emit connect to ${device.current.id!.toLowerCase()}`);
            socket.emit("con", device.current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}');

        }


    }, device.current ? 10000 : null);

    const connect = (deviceParam: any) => {
        const socket = socketRef.current;

        if (!device.current || deviceParam.id !== device.current.id) {
            // disconnect();
            disconnectCallback();
            device.current = deviceParam;
        }

        if (socket?.connected) {
            console.log(`emit connect to ${device.current.id!.toLowerCase()}`);
            socket.emit("con", device.current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}');
        }

    };


    const disconnect = () => {
        const socket = socketRef.current;

        if (!socket) {
            return;
        }
        if (!device.current) {
            return
        }

        const current = device.current;

        device.current = null;

        console.log(`emit disconnect to ${current.id!.toLowerCase()}`, '{"pos":' + laserPos.toString() + '}');
        socket.emit("dis", current.id!.toLowerCase(), '{"pos":' + laserPos.toString() + '}');
    };


    const disconnectCallback = useCallback(disconnect, [laserPos])
    const connectCallback = useCallback(connect, [disconnectCallback, laserPos])



    // return {distance, connect, disconnect };
    return { distance, connect: connectCallback, disconnect: disconnectCallback };
};

export default useLaserSocket;