import { Box, Grid, Paper, Typography } from '@mui/material';
import { useConfigStore } from '../store/store';

import { Buffer } from "buffer";

const Account = () => {
    const configClient = useConfigStore(state => state.config?.client);


    const renderImprint = () => {
        if(configClient){
            if(configClient.imprint_base64 && configClient.imprint_base64.trim().length > 0){
                const base64 = configClient.imprint_base64;
                const html = Buffer.from(base64, 'base64').toString();
    
                return (
                    <div dangerouslySetInnerHTML={{__html:html}} />
                );
            }
            else{
                return (
                    <>
                        <Grid item xs={12} marginBottom={2}>
                            <Typography variant='h6'>{configClient.copyright}</Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography variant='h6'>Support: </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={8} marginBottom={2}>
                            <Typography>{configClient.support}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography variant='h6'>Website: </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={8} marginBottom={2}>
                            {configClient.website?.length && (configClient.website.startsWith("http://") || configClient.website.startsWith("https://") || configClient.website.startsWith("/") ) &&
                                <a href={configClient.website}>
                                    <Typography>{configClient.website}</Typography>
                                </a>
                            }
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography variant='h6'>Datenschutz: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} marginBottom={2}>
                            
                            {configClient.privacy?.length && (configClient.privacy.startsWith("http://") || configClient.privacy.startsWith("https://") || configClient.privacy.startsWith("/") ) &&
                                <a href={configClient.website}>
                                    <Typography>{configClient.privacy}</Typography>
                                </a>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Typography variant='h6'>Anbieter: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8}>
                            <Grid item xs={12}>
                                <Typography>{configClient.company}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{configClient.street} {configClient.housenumber}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{configClient.zipcode} {configClient.location}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{configClient.country}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography>{configClient.phone}</Typography>
                            </Grid>
                            <Grid item xs={12} marginBottom={2}>
                                <Typography>{configClient.email}</Typography>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <Typography variant='h6'>USt-IdNr.: </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={8} marginBottom={2}>
                            <Typography>{configClient.vat_number}</Typography>
                        </Grid>
                    </>
                )
            }
        }

        return null;

    }
   
    return (
        <Box sx={{ backgroundColor: 'lightgrey' }}>

            <Grid container spacing={0} justifyContent={'center'}>

                <Grid item xs={10}>
                    {/* <Box sx={{height: '8vh'}}></Box> */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4' mt={2}>Impressum</Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Paper elevation={3}>
                                <Box sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 3,
                                    wordBreak: { xs:"break-word"}
                                }}>
                                    <Grid container  direction="row" alignItems="flex-start" justifyItems="flex-start" spacing={2}>
                                        {/* <div> */}

                                            {renderImprint()}

                                        {/* </div> */}

                                    </Grid>
                                </Box>

                            </Paper>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );

}

export default Account;