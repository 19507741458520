

import { useEffect, useState } from 'react';
import target01 from '../kalibriertafeln/mt01.png';
import target02 from '../kalibriertafeln/mt02.png';
import target03 from '../kalibriertafeln/mt03.png';
import target04 from '../kalibriertafeln/mt04.png';
import target05 from '../kalibriertafeln/mt05.png';
import target06 from '../kalibriertafeln/mt06.png';
import target09 from '../kalibriertafeln/mt09.png';
import target10 from '../kalibriertafeln/mt10.png';
import target11 from '../kalibriertafeln/mt11.png';
import target12 from '../kalibriertafeln/mt12.png';
import target13 from '../kalibriertafeln/mt13.png';
import target14 from '../kalibriertafeln/mt14.png';
import target15 from '../kalibriertafeln/mt15.png';
import target16 from '../kalibriertafeln/mt16.png';
import target17 from '../kalibriertafeln/mt17.png';
import target18 from '../kalibriertafeln/mt18.png';
import target19 from '../kalibriertafeln/mt19.png';



import { ITargetMapping } from '../store/store';


interface ITarget {
    id:number, 
    src:string, 
    alt: string, 
    name?:string
}

const tmpTargets: ITarget[] = [
    { id: 1, src: target01 , alt: 'target01'},
    { id: 2, src: target02 , alt: 'target02'},
    { id: 3, src: target03 , alt: 'target03'},
    { id: 4, src: target04 , alt: 'target04'},
    { id: 5, src: target05 , alt: 'target05'},
    { id: 6, src: target06 , alt: 'target06'},
    { id: 9, src: target09 , alt: 'target09'},
    { id: 10, src: target10 , alt: 'target10'},
    { id: 11, src: target11 , alt: 'target11'},
    { id: 12, src: target12, alt: 'target12'},
    { id: 13, src: target13 , alt: 'target13'},
    { id: 14, src: target14 , alt: 'target14'},
    { id: 15, src: target15 , alt: 'target15'},
    { id: 16, src: target16 , alt: 'target16'},
    { id: 17, src: target17 , alt: 'target17'},
    { id: 18, src: target18 , alt: 'target18'},
    { id: 19, src: target19 , alt: 'target19'}

];

const useTargets = (mapping:ITargetMapping[]) => {
    const [targets, setTargets] = useState<ITarget[]>([]);


    useEffect(() => {
        
        const newTargets:ITarget[] = [];

        if(mapping){
            let sortedMap = [...mapping];

            sortedMap.sort((a, b) => {
                const aVal = a.order ?? 0;
                const bVal = b.order ?? 0;
                
                return aVal - bVal;
            });

            // let sortedMap:ITargetMapping[] = mapping.toSorted((a, b) => {
            //     const aVal = a.order ?? 0;
            //     const bVal = b.order ?? 0;
                
            //     return aVal - bVal;
            // });
    
            sortedMap?.forEach((targetMapping) => {
                const found = tmpTargets?.find((target) => {
                    return targetMapping.id === target.id;
                });
        
                if(found){
                    found.name = targetMapping.name;
                    newTargets.push(found);
                }
            });
    
            setTargets(newTargets);

        }

    },[mapping])

    return targets;
}
    
export default useTargets;