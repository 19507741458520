import {Box, Button, Card, Grid, TextField, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
// import {useNavigate} from "react-router-dom";
import { Address } from '../generated/pdf';

const Fulfillerdata = () => {
    // const navigate = useNavigate();

    const [monteur, setMonteur] = useState<Address>({
        firstName: '',
        lastName: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        country: '',
        state: '',
        email: '',
        phone: '',
    });

    const initialMonteurFromStorage = localStorage.getItem("monteur");
    let initialMonteur:Address|undefined;

    if(initialMonteurFromStorage){
        try {
            initialMonteur = JSON.parse(initialMonteurFromStorage)
        } catch (error) {
            
        }
    }


    useEffect(() => {
        if(initialMonteur !== undefined){
            setMonteur(initialMonteur)
        }
    }, [initialMonteur]);

    const updateField = (e: any) => {
        setMonteur({
            ...monteur,
            [e.target.name]: e.target.value,
        });
    };

    const finish = () => {
        localStorage.setItem("monteur", JSON.stringify(monteur));

        console.log(monteur);
        alert('Daten wurden lokal gespeichert.');
    };

    return (
        
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }} overflow="auto">
            <Box sx={{height: '5vh'}}></Box>
            <Grid item xs={12} padding={2}>
            <Box sx={{height: '3vh'}}></Box>
                <Card style={{width: '99%'}}>
                    <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center', height: '8vh' }}>
                                <Typography variant='h4' mt={2}>Monteurdaten</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2} alignItems={"left"} justifyContent={"left"} >
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Vorname</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"firstName"} value={monteur.firstName} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Nachname</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"lastName"} value={monteur.lastName} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Unternehmen</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"company"} value={monteur.company} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Adresszeile 1</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"address1"} value={monteur.address1} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Adresszeile 2</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"address2"} value={monteur.address2} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Ort</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"city"} value={monteur.city} onChange={updateField}></TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>PLZ</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"postcode"} value={monteur.postcode} onChange={updateField}></TextField>
                         </Grid>
                         <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Land</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"country"} value={monteur.country} onChange={updateField}></TextField>
                         </Grid>
                         <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>Staat</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"state"} value={monteur.state} onChange={updateField}></TextField>
                         </Grid>
                         <Grid item xs={12} sm={6} lg={2} md={3}>
                            <Typography variant='h6'>E-Mail</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"email"} value={monteur.email} onChange={updateField}></TextField>
                         </Grid>
                         <Grid item xs={12} sm={6} lg={2} md={3}>
                         <Typography variant='h6'>Telefonnummer</Typography>
                            <TextField sx={{ backgroundColor: 'white' }} name={"phone"} value={monteur.phone} onChange={updateField}></TextField>
                        </Grid>
                    </Grid>
                    <Box sx={{ height: '50px' }}></Box>
                    <Grid item xs={12}>
                        <Button variant={"contained"} onClick={finish} sx={{padding: '15px'}} style={{width:300}}>Daten Übernehmen</Button>
                    </Grid>
                    <Box sx={{ height: '50px' }}></Box>
                </Card>
            </Grid>
            
 
            

        </Grid>
    )
}

export default Fulfillerdata;