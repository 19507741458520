import  {useEffect, useState} from 'react';
import './App.css';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, ThemeProvider, createTheme, Theme } from '@mui/material';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Topbar from './components/topbar';
import Home from './routes/home';
import { useAuth } from 'oidc-react';
// import { Theme } from '@emotion/react';
import Account from './routes/account';
import Target from './routes/target';
import Fahrzeugfront from './routes/alignment/fahrzeugfront';
import Frontkamera from './routes/alignment/frontkamera';
import Radnabenmitte from './routes/alignment/radnabenmitte';
import Customerdata from './routes/customerdata';
import Fulfillerdata from './routes/fulfillerdata';
import EinstellwerteScreen from './routes/einstellwerte_screen';
import InstructionsScreen from './routes/instructions';
import { IConfig, useConfigStore, useThemeStore } from './store/store';

import WebFont from "webfontloader";

interface NamedTheme  {
    name: String;
    theme: Theme;
   
}

const defaultTheme = {name: 'wms', theme: createTheme(
    {
        palette: {
            primary: {
               main:  '#20779F',
            },
            secondary: {
                main: '#C2DFEC',
            },
        },
    }
)};

const loadConf = async (themename:string) => {
    try {
        const cfgResponse = await fetch("/config/" + themename + "/cfg.json" , {
            "headers": {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Type',
            },
            cache: "no-cache"
        });

        try {
            const cfg:IConfig = await cfgResponse.json();
            
            if(cfg){
                console.log("cfg:", cfg);
                return cfg;
            }
        } catch (error) {
            console.log("Config kein JSON");
        }

    } catch (error) {
        console.log("Fehler beim lesen der Config");
    }

    return null;
}

function AppContent() {

    // const themeName = useThemeStore(state => state.themeName);
    const setThemeName = useThemeStore(state => state.updateThemeName);

    // const config = useConfigStore(state => state.config);
    const setConfig = useConfigStore(state => state.updateConfig);

    const configThemeFolder = useConfigStore(state => state.config?.themeFolder);


    const auth = useAuth();

    const [theme, setTheme] = useState<NamedTheme>();

    // const [showDialog, setShowDialog] = useState(true);

    //  const handleAccept = () => {
    //    setShowDialog(false);
    //    // Hier können Sie weitere Initialisierungslogik für Ihre App einfügen
    //  };

     const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    // const handleLogoutClick = () => {
    //     setOpenLogoutDialog(true);
    // };
    
    const handleCloseDialog = () => {
        setOpenLogoutDialog(false);
    };
    
    const handleConfirmLogout = () => {
        setOpenLogoutDialog(false);
    };



    useEffect(() => {

        const createThemeFromConfig = async () => {
            const cfg = await loadConf(themeName);

            if(cfg){
                setConfig(cfg);

                // set Titel and Favicon
                document.title = cfg?.title;
                const linkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
                linkElement.setAttribute('rel', 'icon');
                linkElement.setAttribute('href', "/theme/" + cfg.themeFolder + cfg.favicon);

                let tmpTheme = createTheme(
                    {
                        ...cfg.theme
                    }
                );


                tmpTheme = createTheme(tmpTheme, {
                    // Custom colors created with augmentColor go here

                    palette: {
                        customHeader: tmpTheme.palette.augmentColor({
                            color: {
                                main: tmpTheme.palette.customHeader.main,
                            },
                            name: 'customHeader',
                        }),
                        customHeaderButton: tmpTheme.palette.augmentColor({
                            color: {
                                main: tmpTheme.palette.customHeaderButton.main,
                            },
                            name: 'customHeaderButton',
                        })
                    },

                    
                });

                setTheme({name: themeName, theme: tmpTheme});

                setThemeName(themeName);
            }

        }
        

        console.log("theme change", auth.userData?.profile.theme, theme)
        //handleLogoutClick();

        let themeName = auth.userData?.profile.theme as string;

        if(themeName != null){
            if (theme == null || theme.name !== themeName)
            {
                createThemeFromConfig();
            }
            else{
                if(theme == null){
                    setTheme(defaultTheme);
                }
            }

        }


        
    }, [auth.userData?.profile.theme, setConfig, setThemeName, theme]);


    useEffect(() => {

        if(configThemeFolder && configThemeFolder?.length > 0){
            WebFont.load({
                custom: {
                    // families: ['My Font', 'My Other Font:n4,i4,n7'],
                    urls: ['/theme/' + configThemeFolder + '/fonts.css']
                }
            })
        }
       
    }, [configThemeFolder]);

    // if(!config){
    //     return <div>Keine Konfiguration</div>
    // }

    if(theme == null){
        return null;
    }

    return (
        <ThemeProvider theme={theme.theme}>
            {/* Zeigen Sie den Datenschutzhinweis-Dialog an, wenn `showDialog` true ist */}
            
            {/* <p>{theme.name}</p> */}
            <Dialog
                open={openLogoutDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Datenschutzhinweis"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Datenschutzhinweis
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Nein</Button>
                    <Button onClick={handleConfirmLogout} autoFocus>
                        Ja
                    </Button>
                </DialogActions>
            </Dialog>
           
            <Router>
                <Box sx={{height: '100%', minHeight: '100vh',  width: '100vw', backgroundColor: 'lightgrey'}} alignItems={'center'}
                        justifyContent={'center'} className="page-wrapper">
                    
                    {auth.isLoading &&
                        <p>Loading...</p>
                    }
                    {!auth.isLoading &&
                        <>
                            <Topbar/>

                            <Grid 
                                container 
                                alignItems={'center'} 
                                justifyContent={'flex-start'} 
                                // sx={{ backgroundColor: 'lightgrey', padding: '0 20px' }} 
                                className="page-content"
                            >
                                <Routes>
                                    <Route path='/' element={<Home/>}/> 
                                    <Route path='/account' element={<Account/>}/>
                                    <Route path='/target' element={<Target/>}/>
                                    <Route path='/fahrzeugfront' element={<Fahrzeugfront/>}/>
                                    <Route path='/frontkamera' element={<Frontkamera/>}/>
                                    <Route path='/radnabenmitte' element={<Radnabenmitte/>}/>
                                    <Route path='/customerdata' element={<Customerdata/>}/>
                                    <Route path='/fulfillerdata' element={<Fulfillerdata/>}/>
                                    <Route path='/einstellwerte' element={<EinstellwerteScreen/>}/> 
                                    <Route path='/instructions' element={<InstructionsScreen/>}/> 
                                </Routes>

                            </Grid>
                    
                        </>
                    }
                </Box>
            </Router>
            
        </ThemeProvider>
    );
}

export default AppContent;
