import {create} from "zustand";
import { SuperJob } from "../generated/pdf";
import { Theme } from "@mui/material";
import { Workplace } from "../generated/workplace";

// import { produce } from 'immer'

interface ITheme {
    themeName: string;
    updateThemeName: (newThemeName: string) => void;
}

export const useThemeStore = create<ITheme>((set) => ({
    themeName: "",
    updateThemeName: (newThemeName: string ) => set({ themeName: newThemeName })
}));


export interface ITargetMapping {
    id: number,
    name: string,
    order?: number
}

interface IClient {
    copyright?: string,
    support?: string,
    website?: string,
    privacy?: string,
    company?: string,
    street?: string,
    housenumber?: string,
    zipcode?: string,
    location?: string,
    country?: string,
    vat_number?: "",
    phone?: string,
    email?: string,

    imprint_base64?: string
}

export interface IConfig  {
    
    themeName: string,
    themeFolder: string,
    title: string,
    favicon: string,
    primary: string,
    secondary: string,

    logo: string,

    license: string[],

    target_mapping: ITargetMapping[],

    client: IClient,

    theme: Theme

}

interface IConfigStore {
    config?: IConfig,
    updateConfig: (newConfig: IConfig) => void;

    hasLicense: (key: string) => boolean;

}

export const useConfigStore = create<IConfigStore>((set, get) => ({
    config: undefined,
    // config: {
    //     themeName: "",
    //     themeFolder: "",
    //     title: "",
    //     favicon: "",
    //     primary: "",
    //     secondary: "",
    //     logo: "",
    //     fonts: "",
    //     allowed_modules: [],
    //     target_mapping: [],
    //     imprint: []
    // },
    updateConfig: (newConfig: IConfig ) => set({ config: newConfig }),
    hasLicense: (key) => {
        return get().config?.license?.findIndex((itm) => itm === key) !== -1;
    },
}));



interface ISuperJob {
    superJob: SuperJob;
    updateSuperJob: (newSuperJob: SuperJob) => void;
    setSuperJobValue: (key: string, value: any ) => void;
    resetSuperJob: () => void;

}

export const useSuperJobStore = create<ISuperJob>((set) => ({
    superJob: {},
    updateSuperJob: (newSuperJob: SuperJob ) => set({ superJob: newSuperJob }),
    // setSuperJobValue: (key: string, value: any ) => set({ [key]: value }),

    setSuperJobValue: (key: string, value: any ) =>
        set((state) => ({
            superJob: { ...state.superJob, [key]: value },
        })),

        // set(
        //   produce((state) => {
        //     state[key] = value
        //   }),
        // ),

    resetSuperJob: () => set({ superJob: {} })
}));


interface IValues {
    messtyp: string,
    updateMesstyp: (newVal: string) => void
}

export const useValueStore = create<IValues>((set) => ({
    messtyp: "",
    updateMesstyp: (newVal: string ) => set({ messtyp: newVal })
}));


/*

const themeName = useThemeStore(state => state.themeName);
const setThemeName = useThemeStore(state => state.updateThemeName);

*/



interface IStation {
    station: Workplace;
    setStation: (newStation:Workplace) => void;
}

export const useStationStore = create<IStation>((set) => ({
    station: {},
    
    setStation: (newStation:Workplace) => set({ 
        station: {
            ...newStation
        } 
    })
}));