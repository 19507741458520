import {Fragment, useEffect, useState} from 'react';
import {Grid, Box, Button, Card} from '@mui/material';
// import {useCookies} from "react-cookie";
import {Einstellwerte, ImagesApi} from "../generated/car";
// import { useLocation, useNavigate } from 'react-router-dom';
// import radnabenmitte_img from '../images/radnabenmitte_vertikal.png';
// import { Api } from '@mui/icons-material';
// import { Link } from 'react-router-dom';


interface BeschreibungsItem {
    T: string;
    A: string;
    // Fügen Sie hier weitere Eigenschaften hinzu, die 'beschreibung'-Elemente haben könnten
}

// interface ListItem {
//     T: string;
//     A: string;
//     B: string | null;
//   }

const EinstellwerteScreen = () => {

    // const [cookies, setCookies] = useCookies();
    const [einstellwert, setEinstellwert] = useState<Einstellwerte>({});
    const [beschreibung, setBeschreibung] = useState<any>([]);
    const [anleitung, setAnleitung] = useState<any>([]);
    const [werte, setWerte] = useState<any>({});
    // const navigate = useNavigate();
    // const location = useLocation();
    // const [imageData, setImageData] = useState('');
    // const [imageName, setImageName] = useState('');
    const [loadedImages, setLoadedImages] = useState([{name: "", value: ""}]);
    const [testImages, setTestImages] = useState([{name: "", value: ""}]);
    let bildIndex = 0;

    // const gotoCorrectPage = () => {

    //     if(location.pathname === "/einstellwerte"){
    //         let messTyp = cookies['einstellwert'].messTypId;
    //         switch (messTyp){
    //             case 2:
    //                 navigate("/fahrzeugfront")
    //                 break
    //             case 3:
    //                 navigate("/radnabenmitte")
    //                 break
    //             case 4:
    //                 navigate("/frontkamera")
    //                 break
    //             default:
    //                 alert("Kein Messtyp ausgewählt")
    //         }

    //     }

    // }

    const localEinstellwert = localStorage.getItem('einstellwert');

    useEffect(() => {
        //setEinstellwert(cookies['einstellwert']);

        let ew = JSON.parse(localEinstellwert ?? "{}");
        console.log(ew);
        let ewe: Einstellwerte = ew;
        setEinstellwert(ewe);
        console.log(ewe.EinstellArt?.bezeichnung);
        
    },[localEinstellwert]);




    useEffect(() => {
        //let api: ImagesApi = new ImagesApi();

        if(einstellwert && einstellwert.beschreibung !== undefined) {
            let json = JSON.parse(einstellwert.beschreibung);
            if(einstellwert.werte !== undefined && einstellwert.werte.length > 0){
                let jsonWerte = JSON.parse(einstellwert.werte);console.log("")
                setWerte(jsonWerte);
            }
            setBeschreibung(json.List);
            
            // Sammeln aller Bildnamen, wenn T === 'IS'

            console.log(json.List);
        }
    },[einstellwert]);

    

    useEffect(() => {
        if (beschreibung) {
            // [1,2,3,4]
            //let imageUrls: string[] = [];
            let imageUrls: any =  [];
            const api = new ImagesApi();
            beschreibung.forEach((item: BeschreibungsItem) => {
                if (item.T === 'IS') {
                    //async 
                    api.getTarget({"name": item.A})
                        .then((response) => {
                            let name = item.A
                           

                            let obj = {name : name, value : URL.createObjectURL(response) }
                            // 1 2 3 4 
                    
                            imageUrls.push(obj);
                            if (imageUrls.length === beschreibung.filter((item: BeschreibungsItem) => item.T === 'IS').length) {
                                setLoadedImages(imageUrls);
                                console.log("URLS"+imageUrls);
                                console.log(imageUrls);

                            }
                        })
                        .catch(error => {
                            console.error("Fehler beim Laden des Bildes:", error);
                        });
                }
            });
        }
    }, [beschreibung]);

    useEffect(() => {
        if (anleitung) {
            // [1,2,3,4]
            //let imageUrls: string[] = [];
            let imageUrls: any =  [];
            const api = new ImagesApi();
            anleitung.forEach((item: BeschreibungsItem) => {
                if (item.T === 'IS') {
                    //async 
                    api.getTarget({"name": item.A})
                        .then((response) => {
                            let name = item.A
                           

                            let obj = {name : name, value : URL.createObjectURL(response) }
                            // 1 2 3 4 
                    
                            imageUrls.push(obj);
                            if (imageUrls.length === anleitung.filter((item: BeschreibungsItem) => item.T === 'IS').length) {
                                setTestImages(imageUrls);
                                console.log("URLS"+imageUrls);
                                console.log(imageUrls);
                                // console.log(testImages);
                            }
                        })
                        .catch(error => {
                            console.error("Fehler beim Laden des Bildes:", error);
                        });
                }
            });
        }
    }, [anleitung]);


      const openLinkInNewTab = (path: any) => {
        console.log(path);
        window.open(path);
      };

    return <Grid container alignItems={'center'} justifyContent={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey', padding: '0 0' }} margin={0}>
        <Grid item lg={12}>
            {/* <Box sx={{height: '8vh'}}></Box> */}
            <Card style={{ maxWidth: "100%", wordBreak: "break-word" }} >
            
                {beschreibung.map((beschreibung: any, index:number) => {
                    const elKey = "desc_" + index;
                    let beschreibungB = beschreibung.B;
                    for (const key of Object.keys(werte)) {
                        if( beschreibungB === null|| beschreibungB === undefined){
                            continue;
                        }
                        let regex = new RegExp("{"+key.toLowerCase()+"}", "g");
                        beschreibungB = beschreibungB.toLowerCase().replace(regex, werte[key]);
                    }
                    if(beschreibung.T === 'H1'){
                        return <h1 key={elKey} style={{ textAlign: 'left', paddingLeft: '20px'}}>{beschreibung.A}</h1>;
                    }else if(beschreibung.T === 'TR') {
                        return <p key={elKey} style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}: {beschreibungB} </p>
                    }else if(beschreibung.T === 'H3' ) {
                        return <Fragment key={elKey}>
                            <h3 style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</h3>
                            <h3>{beschreibungB}</h3>
                        </Fragment>
                    }else if(beschreibung.T === 'P'){
                        return <Fragment key={elKey}>
                            <p style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</p>
                            <p>{beschreibungB}</p>
                        </Fragment>

                    }else if(beschreibung.T === 'IS') {
                        let name: string = beschreibung.A
                        console.log(loadedImages.find(src => src.name === name ));
                        //const imgSrc = loadedImages[bildIndex];
                        const imgSrc = loadedImages.find(src => src.name === name )?.value
                        bildIndex++;
                        console.log(imgSrc);
                        
                            return (
                                <div key={elKey}>
                                    {imgSrc && <img style={{ textAlign: 'left', paddingLeft: '20px', maxWidth:"100%" }} src={imgSrc} alt={`Bild ${bildIndex} ${beschreibung.A}`} />}
                                </div>
                            )
                            
                            
                        
                    }else if(beschreibung.T === 'IL'){
                        return <Fragment key={elKey}>
                            <p style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</p>
                            <p>{beschreibungB}</p>
                        </Fragment>
                    }
                    return <p key={elKey}>Not Implemented</p>;
                })}
                <div>
                    <Button variant={"contained"} onClick={() => openLinkInNewTab(window.location.origin+'/instructions')} fullWidth style={{height: '5vh'}}>Anleitung anzeigen</Button>
                </div>
            </Card>
        </Grid>
    </Grid>
}


export default EinstellwerteScreen;