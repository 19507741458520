import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import React, {useState} from 'react';
import {ChevronLeft, Menu} from '@mui/icons-material';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, useTheme} from '@mui/material';
import {Link} from 'react-router-dom';
import {useAuth} from "oidc-react";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AccountIcon from '@mui/icons-material/AccountCircle';


const DrawerComponent = () => {
    const auth = useAuth();
    const theme = useTheme();

    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = (open: boolean) => (event: any) => {
        setOpenDrawer(open);
    }

    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

    const handleLogoutClick = () => {
        setOpenLogoutDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenLogoutDialog(false);
    };
    
    const handleConfirmLogout = () => {
        auth.signOutRedirect();
        setOpenLogoutDialog(false);
    };

    // Vorbereitung Target zuruecksetzen in Navi auslagern
    
    // const [openResetTargetDialog, setOpenResetTargetDialog] = useState(false);

    // const handleResetTargetClick = () => {
    //     setOpenResetTargetDialog(true);
    // };
    
    // const handleCloseResetTargetDialog = () => {
    //     setOpenResetTargetDialog(false);
    // };
    
    // const handleConfirmResetTarget = () => {
    //     closeJobs();
    //     setOpenResetTargetDialog(false);
    // };

    // function closeJobs(){
    //     console.log(station);
    //     let jobApi = new JobApi();
    //     jobApi.closeJobs({"device":station})
    // }



    return <React.Fragment key={'left'}>
        <Button 
            sx={{color: theme.palette.customHeaderButton.main}} 
            onClick={toggleDrawer(true)} 
            // style={{color: 'white'}}
        >
            <Menu/>
        </Button>
        <Drawer
            anchor={'left'}
            open={openDrawer}
            onClose={toggleDrawer(false)}
        >
        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 8px', justifyContent: 'flex-end' }}>
                    <IconButton onClick={toggleDrawer(false)}>
                        <ChevronLeft />
                    </IconButton>
                </Box>
                <Divider />
        <Box  sx={{minWidth: '20vw',padding: '8px'}}>
            <Link to={"/"} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <Button 
                sx={{ width: '100%', height: '8vh', justifyContent: 'flex-start', margin: '4px'}} 
                variant="contained"
                startIcon={<HomeIcon />}
                >
                    Home
                </Button>
            </Link>
            
            <Link to={'/fulfillerdata'} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <Button 
                sx={{ width: '100%', height: '8vh', justifyContent: 'flex-start',margin: '4px' }} 
                variant="contained"
                startIcon={<AccountIcon />}
                >
                    Monteur Daten
                </Button>
            </Link>
            <Link to={'/account'} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <Button 
                sx={{ width: '100%', height: '8vh', justifyContent: 'flex-start',margin: '4px' }} 
                variant="contained"
                startIcon={<SettingsIcon />}
                >
                    Impressum
                </Button>
            </Link>
            
            <div style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                <Button 
                    sx={{ width: '100%', height: '8vh', justifyContent: 'flex-start', margin: '4px'}} 
                    variant="contained"
                    onClick={handleLogoutClick}
                    startIcon={<LogoutIcon/>}
                >
                    Log out
                </Button>
            </div>
            
        </Box>
     
        </Drawer>
        <Dialog
        open={openLogoutDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Ausloggen bestätigen"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Sind Sie sicher, dass Sie sich ausloggen möchten?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Nein</Button>
            <Button onClick={handleConfirmLogout} autoFocus>
                Ja
            </Button>
        </DialogActions>
    </Dialog>
    </React.Fragment>
}


export default DrawerComponent;