/* tslint:disable */
/* eslint-disable */
/**
 * Workplace
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Workplace,
} from '../models';

export interface GetWorplaceRequest {
    id: string;
}

/**
 * 
 */
export class WorkplaceApi extends runtime.BaseAPI {

    /**
     * Listet alle Workplace welche mit dem aktuellen Account verknüpft sind 
     */
    async getWorkplacesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Workplace>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Listet alle Workplace welche mit dem aktuellen Account verknüpft sind 
     */
    async getWorkplaces(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Workplace>> {
        const response = await this.getWorkplacesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Liefert den aktuellen Workplace für die übergebene ID zurück
     */
    async getWorplaceRaw(requestParameters: GetWorplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Workplace>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getWorplace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workplace/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Liefert den aktuellen Workplace für die übergebene ID zurück
     */
    async getWorplace(requestParameters: GetWorplaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Workplace> {
        const response = await this.getWorplaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
