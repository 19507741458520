/* tslint:disable */
/* eslint-disable */
/**
 * Car
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Kba,
} from '../models';

export interface GetForKbaRequest {
    kba1: string;
    kba2: string;
}

export interface GetKbaRequest {
    manufacturerId: number;
    modelId: number;
    typeId: number;
}

/**
 * 
 */
export class KbaApi extends runtime.BaseAPI {

    /**
     */
    async getForKbaRaw(requestParameters: GetForKbaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Kba>> {
        if (requestParameters.kba1 === null || requestParameters.kba1 === undefined) {
            throw new runtime.RequiredError('kba1','Required parameter requestParameters.kba1 was null or undefined when calling getForKba.');
        }

        if (requestParameters.kba2 === null || requestParameters.kba2 === undefined) {
            throw new runtime.RequiredError('kba2','Required parameter requestParameters.kba2 was null or undefined when calling getForKba.');
        }

        const queryParameters: any = {};

        if (requestParameters.kba1 !== undefined) {
            queryParameters['kba1'] = requestParameters.kba1;
        }

        if (requestParameters.kba2 !== undefined) {
            queryParameters['kba2'] = requestParameters.kba2;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kba`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async getForKba(requestParameters: GetForKbaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Kba> {
        const response = await this.getForKbaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Liefert die KBA Nummer zurück 
     */
    async getKbaRaw(requestParameters: GetKbaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Kba>> {
        if (requestParameters.manufacturerId === null || requestParameters.manufacturerId === undefined) {
            throw new runtime.RequiredError('manufacturerId','Required parameter requestParameters.manufacturerId was null or undefined when calling getKba.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getKba.');
        }

        if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
            throw new runtime.RequiredError('typeId','Required parameter requestParameters.typeId was null or undefined when calling getKba.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kba/{manufacturerId}/{modelId}/{typeId}`.replace(`{${"manufacturerId"}}`, encodeURIComponent(String(requestParameters.manufacturerId))).replace(`{${"modelId"}}`, encodeURIComponent(String(requestParameters.modelId))).replace(`{${"typeId"}}`, encodeURIComponent(String(requestParameters.typeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Liefert die KBA Nummer zurück 
     */
    async getKba(requestParameters: GetKbaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Kba> {
        const response = await this.getKbaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
