import {
    Box,
    Grid
} from '@mui/material';

import DatabaseSelection from '../components/databaseSelection';
import ManualSelection from '../components/manualSelection';
import { useConfigStore } from '../store/store';

const Home = () => {
    const hasLicense = useConfigStore(state => state.hasLicense);
   
    return (
        <Grid container alignItems={'center'} justifyContent={'center'} textAlign={'center'} spacing={0} sx={{ backgroundColor: 'lightgrey' }}>
            <Grid  item xs={12} justifyContent={'center'} alignItems={'center'}>
                
                <ManualSelection />

                <Box sx={{height: '1vh'}}></Box>

                {hasLicense("database") &&
                    <DatabaseSelection />
                }

            </Grid>
        </Grid>
    );
}

export default Home;