// socket.js
import { io, Socket } from "socket.io-client";

let sockets: {
    url: string;
    socket: Socket
}[] = [];

export const getSocket = (url: string) => {

    const found = sockets.find((itm) => {
        return itm.url === url;      
    });

    let socket = null;

    if(found != null){
        socket = found.socket;
    }

    if (!socket) {
        socket = io(url, {
            transports: ["websocket"]

        }); // Initialize the socket only once

        sockets.push({
            url: url,
            socket: socket
        });

    }

    return socket;
};