import {useEffect, useState} from 'react';
import {Grid, Box, Card} from '@mui/material';
import {ImagesApi} from "../generated/car";


interface BeschreibungsItem {
    T: string;
    A: string;
    // Fügen Sie hier weitere Eigenschaften hinzu, die 'beschreibung'-Elemente haben könnten
}

const InstructionsScreen = () => {

    const [anleitung, setAnleitung]  = useState<any>([]);
    // const [werte, setWerte] = useState<any>({});
    const [loadedImages, setLoadedImages] = useState([{name: "", value: ""}]);
    let bildIndex = 0;


    const anleitungFrontkamera = localStorage.getItem('anleitungFrontkamera');
    const anleitungDistanzregelung = localStorage.getItem('anleitungDistanzregelung');

    useEffect(() => {
        if(localStorage.getItem('kalibrierart') === 'Einstellwerte - Frontkamera'){
            let anleitung = JSON.parse(anleitungFrontkamera ?? "{}");
            let anleitungParse = JSON.parse(anleitung.beschreibung);
            console.log(anleitungParse);
            setAnleitung(anleitungParse.List);
        }else{
            let anleitung = JSON.parse(anleitungDistanzregelung ?? "{}");
            let anleitungParse = JSON.parse(anleitung.beschreibung);
            console.log(anleitungParse);
            setAnleitung(anleitungParse.List);
        }
        
    }, [anleitungDistanzregelung, anleitungFrontkamera]);

    useEffect(() => {
        if (anleitung) {
            // [1,2,3,4]
            //let imageUrls: string[] = [];
            let imageUrls: any =  [];
            const api = new ImagesApi();
            anleitung.forEach((item: BeschreibungsItem) => {
                if (item.T === 'IS') {
                    //async 
                    api.getTarget({"name": item.A})
                        .then((response) => {
                            let name = item.A
                           

                            let obj = {name : name, value : URL.createObjectURL(response) }
                            // 1 2 3 4 
                    
                            imageUrls.push(obj);
                            if (imageUrls.length === anleitung.filter((item: BeschreibungsItem) => item.T === 'IS').length) {
                                setLoadedImages(imageUrls);
                                console.log("URLS"+imageUrls);
                                console.log(imageUrls);
                                // console.log(loadedImages);
                            }
                        })
                        .catch(error => {
                            console.error("Fehler beim Laden des Bildes:", error);
                        });
                }
            });
        }
    }, [anleitung]);

    return <Grid container alignItems={'center'} justifyContent={'center'} spacing={3} sx={{ backgroundColor: 'lightgrey', padding: '0 20px' }}>
    <Grid item lg={12}>
    <Box sx={{height: '8vh'}}></Box>
        <Card>
        
        {anleitung.map((beschreibung: any) => {
            let beschreibungB = beschreibung.B;
            
            // for (const key of Object.keys(werte)) {
            //     if( beschreibungB === null|| beschreibungB === undefined){
            //         continue;
            //     }
            //       let regex = new RegExp("{"+key.toLowerCase()+"}", "g");
            //       beschreibungB = beschreibungB.toLowerCase().replace(regex, werte[key]);
            // }

            if(beschreibung.T === 'H1'){
               return <h1 style={{ textAlign: 'left', paddingLeft: '20px'}}>{beschreibung.A}</h1>;
            }else if(beschreibung.T === 'TR') {
                return <p style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}: {beschreibungB} </p>
            }else if(beschreibung.T === 'H3' ) {
                return <>
                    <h3 style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</h3>
                    <h3>{beschreibungB}</h3>
                </>
            }else if(beschreibung.T === 'P'){
                return <>
                    <p style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</p>
                    <p>{beschreibungB}</p>
                </>

            }else if(beschreibung.T === 'IS') {
                let name: string = beschreibung.A
                console.log(loadedImages.find(src => src.name === name ));
                //const imgSrc = loadedImages[bildIndex];
                const imgSrc = loadedImages.find(src => src.name === name )?.value
                bildIndex++;
                return (
                    <div>
                    {imgSrc && <img style={{ textAlign: 'left', paddingLeft: '20px' }} src={imgSrc} alt={`Bild ${bildIndex} ${beschreibung.A}`} />}
                    </div>
                )
            }else if(beschreibung.T === 'IL'){
                return <>
                    <p style={{ textAlign: 'left', paddingLeft: '20px' }}>{beschreibung.A}</p>
                    <p>{beschreibungB}</p>
                </>
            }
            return <p>Not Implemented</p>;
        })}
        
        </Card>
    </Grid>
</Grid>
}


export default InstructionsScreen;