import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {JobApi} from "../generated/job";
import {useCookies} from "react-cookie";
import {PDFApi, SuperJob, Address, SuperJobAdditionalInfoInner} from "../generated/pdf";
import {
     Einstellwerte,

} from "../generated/car";
import { useSuperJobStore, useThemeStore, useValueStore } from '../store/store';

const Customerdata = () => {
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies();

    const themeName = useThemeStore(state => state.themeName);
    
    const superJob = useSuperJobStore(state => state.superJob);
    const resetSuperJob = useSuperJobStore(state => state.resetSuperJob);

    const globalMessTyp = useValueStore(state => state.messtyp);
    // const setGlobalMessTyp = useValueStore(state => state.updateMesstyp);


    const jobApi: JobApi = new JobApi();
    const pdfApi: PDFApi = new PDFApi();
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Formatieren des Datums als "YYYY-MM-DD"
    const [customer, setCustomer] = useState({
        kundenname: '',
        kennzeichen: '',
        vinNummer: '',
        bemerkung: '',
    });

    const updateField = (e: any) => {
        setCustomer({
            ...customer,
            [e.target.name]: e.target.value,
        });
    };

    const finish = () => {
        console.log(customer);

        // var sj: SuperJob = cookies['superjob'];
        let sj: SuperJob = superJob;

        let sjInfoInner : SuperJobAdditionalInfoInner;
        sj.hist = cookies['hist'] ? cookies['hist'].toString() : '';
        sj.list = cookies['list'] ? cookies['list'].toString() : '';
        sj.rist = cookies['rist'] ? cookies['rist'].toString() : '';
        sj.listphase2 = cookies['list_phase2'] ? cookies['list_phase2'].toString() : '';;
        sj.ristphase2 = cookies['rist_phase2'] ? cookies['rist_phase2'].toString() : '';

        const monteurFromStorage = localStorage.getItem("monteur");
        let monteur:Address|undefined;
    
        if(monteurFromStorage){
            try {
                monteur = JSON.parse(monteurFromStorage)
            } catch (error) {
                
            }
        }
        sj.fulfiller = monteur;

        // sj.theme = cookies['themeName'];
        sj.theme = themeName;
        // sj.fahrzeug = cookies['car'];
        
        sj.step = globalMessTyp;
        // sj.step = cookies['messtyp'];

        sj.alignmentType = cookies['alignmentType'];
        // sj.themeName = cookies['themeName'];
        sj.themeName = themeName;
        sj.mehrphasenkalibrierung = cookies['Mehrphasenkalibrierung'];

        
        let myew: Einstellwerte = cookies['einstellwert'];
        var jsonWerte: any;
        if(myew.werte != null && myew.werte.length > 0) {
            jsonWerte = JSON.parse(myew.werte);
        }
        if(jsonWerte != null) {
            sj.hsoll = jsonWerte['Höhe_Kalibriertafel'];
            sj.lsoll = jsonWerte['Abstand_Kalibriertafel'];
            sj.rsoll = jsonWerte['Abstand_Kalibriertafel'];
            sj.lsollphase2 = jsonWerte['Abstand_Kalibriertafel_Phase2'];
            sj.rsollphase2 = jsonWerte['Abstand_Kalibriertafel_Phase2'];
        }
        if(myew.kalibriertafelID != null) {
            sj.target = myew.kalibriertafelID.toString();
        }

        let cust: Address = {
            "firstName": customer.kundenname,
            "lastName": '',
            "city": '',
            "postcode": '',
            "country": '',
            "email": ''
        };
       // sj.customer = new class implements Address {}
        sj.customer = cust;
        sj.kennzeichen = customer.kennzeichen;
        sj.vin = customer.vinNummer;
        sjInfoInner = {value: customer.bemerkung, name: "bemerkung"}
        sj.additionalInfo = [sjInfoInner];
        
        
                console.log("Hist" + sj.hist);
                console.log("list" + sj.list);
                console.log("rist" + sj.rist);

                console.log("listphase2" + sj.listphase2);
                console.log("ristphase2" + sj.ristphase2);
                console.log("fulfiller" + sj.fulfiller);

                console.log("theme" + sj.theme);
                console.log("fahrzeug" + sj.fahrzeug);
                console.log("step" + sj.step);

                console.log("theme" + sj.alignmentType);
                console.log("fahrzeug" + sj.themeName);
                console.log("step" + sj.mehrphasenkalibrierung);

        jobApi.getJob({"id": cookies['jobId']}).then(data => {
            pdfApi.postPdf(
                {
                    "superJob": sj
                }
            ).then(blob => {
                console.log(blob);
                console.log("Hist" + sj.hist);
                console.log("list" + sj.list);
                console.log("rist" + sj.rist);

                console.log("listphase2" + sj.listphase2);
                console.log("ristphase2" + sj.ristphase2);
                console.log("fulfiller" + sj.fulfiller);

                console.log("theme" + sj.theme);
                console.log("fahrzeug" + sj.fahrzeug);
                console.log("step" + sj.step);

                console.log("theme" + sj.alignmentType);
                console.log("fahrzeug" + sj.themeName);
                console.log("step" + sj.mehrphasenkalibrierung);

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const pdfFileName = `Ausrichtungsprotokoll_${formattedDate}.pdf`; // Setze hier den gewünschten Dateinamen ein
                link.setAttribute('download', pdfFileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        });



        if(cookies['jobId'] !== undefined && cookies['jobId'] !== -1) {
            jobApi.changeState({
                "id": cookies['jobId'],
                "state": "Completed",
            })
        }
        setCookies('jobId', -1);

        // SuperJob abloeschen
        resetSuperJob();

        navigate("/");
    }

    return (
        <Grid container direction='column' alignItems={'center'} textAlign={'center'}>
            <Grid item xs={12}><Box height={"100px"}> </Box></Grid>
            <Grid item xs={12}><Typography variant='h3'>Kundendaten</Typography></Grid>
            <Grid item xs={12}><Typography variant='h6'>Kundenname</Typography></Grid>
            <Grid item xs={12}><TextField name="kundenname" value={customer.kundenname} onChange={updateField}
                                          sx={{backgroundColor: 'white'}}></TextField></Grid>
            <Grid item xs={12}><Typography variant='h6'>Kennzeichen</Typography></Grid>
            <Grid item xs={12}><TextField name="kennzeichen" value={customer.kennzeichen} onChange={updateField}
                                          sx={{backgroundColor: 'white'}}></TextField></Grid>
            <Grid item xs={12}><Typography variant='h6'>VIN-Nummer</Typography></Grid>
            <Grid item xs={12}><TextField name="vinNummer" value={customer.vinNummer} onChange={updateField}
                                          sx={{backgroundColor: 'white'}}></TextField></Grid>
            <Grid item xs={12}><Typography variant='h6'>Bemerkung (Optional)</Typography></Grid>
            <Grid item xs={12}><TextField name="bemerkung" value={customer.bemerkung} onChange={updateField}
                                          sx={{backgroundColor: 'white'}}></TextField></Grid>
            <Grid item xs={12}><Box height={"30px"}> </Box></Grid>
            <Grid item xs={12}><Button variant={"contained"} onClick={finish}>PDF Erstellen</Button></Grid>
        </Grid>
    );
}

export default Customerdata;